(function (){
  const main = document.querySelector<HTMLElement>('main');
  const path = document.querySelector<SVGPathElement>('#path');
  const plane = document.querySelector<SVGGElement>("#plane");

  plane.setAttribute('transform-box', 'fill-box');
  plane.setAttribute('transform-origin', 'center center');
  plane.style.opacity = '1';

  function positionPlane() {
    if (window.innerWidth < 1440) {
      return;
    }

    const offsetX = path.getBoundingClientRect().x;

    const scrollY = (window.scrollY || window.pageYOffset) - main.offsetTop + 64;
    const maxScrollY = document.documentElement.scrollHeight - window.innerHeight - main.offsetTop + 64;

    const pathLen = path.getTotalLength();
    let dist = pathLen * scrollY / maxScrollY - 3;
    if (dist < 0) {
      dist = 0;
    }

    const pos = path.getPointAtLength(dist);

    let angle: number;

    if (dist+1 <= pathLen) {
      const posAhead = path.getPointAtLength(dist + 1);
      angle = Math.atan2(posAhead.y - pos.y, posAhead.x - pos.x);
    } else {
      const posBehind = path.getPointAtLength(dist - 1);
      angle = Math.atan2(pos.y - posBehind.y, pos.x - posBehind.x);
    }

    plane.style.transform = `translate(${pos.x + offsetX - 84}px, ${pos.y - 42}px) rotate(${rad2deg(angle)}deg)`;
  }

  function rad2deg(rad) {
    return 180 * (rad) / Math.PI;
  }

  positionPlane();

  window.addEventListener('scroll', positionPlane);
  let hasListener = true;

  window.addEventListener('resize', (e) => {
    if (window.innerWidth < 1440) {
      window.removeEventListener('scroll', positionPlane);
      hasListener = false;
      return;
    }

    positionPlane();

    if (!hasListener) {
      window.addEventListener('scroll', positionPlane);
    }
  });
})()
